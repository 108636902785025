import React, {useEffect, useState} from 'react';
import * as actions from '../store/actions';
import {updateOfferSelectionSubscriptionDuration, updateOfferSelectionSubscriptionRoom} from '../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {useHistory} from 'react-router';
import FsLightbox from 'fslightbox-react';
import RatingStars from '../components/RatingStars';
import RatingTripadvisor from '../components/RatingTripadvisor';
import {MenuItem, Select} from '@mui/material';
import {CropOriginal, Info} from '@mui/icons-material';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import HotelDetailsFAQ from '../components/HotelDetailsFAQ';
import HotelDetailsSpecialDates from "../components/HotelDetailsSpecialDates";
import {MapsWindowHotelDetail} from "../components/MapsWindowHotelDetail";
import {ButtonBack} from "../components/Buttons";
import LoadingSpinner from "../assets/svg/LoadingSpinner";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {HotelBadgeDiscount} from "../components/HotelBadgeDiscount";
import {HotelBadgeSavings} from "../components/HotelBadgeSavings";
import BusinessSubscriptionHelp from "../components/BusinessSubscriptionHelp";


const HotelDetails = () => {

  const dispatch = useDispatch()
  const history = useHistory()

  const { city, id } = useParams()
  // fallback to support deprecated route /hotel/:id/ instead of /hotel/:city/:id/
  const hotelId = [0,4,6,8,10,12,14,16].includes(Number(id)) ? city : id

  const searchState = useSelector(state => state.search)
  const nightsLoaded = searchState.nightListCurrentProduct.length > 0
  const nights = searchState.searchNights
  const product = searchState.searchProduct

  useEffect(() => {
    if(nightsLoaded) {
      dispatch(actions.getHotelDetail(hotelId, product, nights))
      dispatch(actions.updateOfferSelectionSubscriptionCategory(nights))
      dispatch(actions.updateOfferSelectionNights([]))
    }
  }, [dispatch, hotelId, nights, product, nightsLoaded])

  const offer = useSelector(state => state.offer)
  const hotelDetail = useSelector(state => state.offer.hotelDetail)
  const isLoading = useSelector(state => state.search.hotelDetailsLoading)
  const subscriptionCategory = useSelector(state => state.offer.subscriptionCategory)
  const subscriptionRoom = useSelector(state => state.offer.subscriptionRoom)
  const subscriptionDuration = useSelector(state => state.offer.subscriptionDuration)
  const pricePerMonth = useSelector(state => state.offer.pricePerMonth)
  const pricePerNight = useSelector(state => state.offer.pricePerNight)
  const pricePerMonthWithDiscount = useSelector(state => state.offer.pricePerMonthWithDiscount)
  const pricePerNightWithDiscount = useSelector(state => state.offer.pricePerNightWithDiscount)
  const discountPerMonth = useSelector(state => state.offer.discountPerMonth)
  const discountPerNight = useSelector(state => state.offer.discountPerNight)
  const savingsInPercent = useSelector(state => state.offer.savingsInPercent)
  const priceStyleWhileUpdating = useSelector(state => state.offer.priceStyleWhileUpdating)
  const rooms = hotelDetail?.BookingRooms || []

  let media1url = '/hotel-room-icon-03.png'
  let media2url = '/hotel-room-icon-03.png'
  let media3url = '/hotel-room-icon-03.png'
  let media1uuid = ''
  let media2uuid = ''
  let media3uuid = ''
  if (Array.isArray(hotelDetail.BookingMedia) && hotelDetail.BookingMedia.length > 0) {
    media1uuid = hotelDetail.BookingMedia.filter(picture => picture.position === '1')[0]?.uuid || hotelDetail.BookingMedia[0]?.uuid
    if(media1uuid) media1url = '/api/booking/media/'+media1uuid+'?width=600'
    media2uuid = hotelDetail.BookingMedia.filter(picture => picture.position === '2')[0]?.uuid || hotelDetail.BookingMedia[1]?.uuid
    if(media2uuid) media2url = '/api/booking/media/'+media2uuid+'?width=400'
    media3uuid = hotelDetail.BookingMedia.filter(picture => picture.position === '3')[0]?.uuid || hotelDetail.BookingMedia[2]?.uuid
    if(media3uuid) media3url = '/api/booking/media/'+media3uuid+'?width=400'
  }

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  })

  const openLightboxOnSlide = (uuid) => {
    if(uuid) {
      const isActiveImage = (element) => element.uuid === uuid
      const number = hotelDetail.BookingMedia.findIndex(isActiveImage)+1
      setLightboxController({
        toggler: !lightboxController.toggler,
        slide: number
      })
    }
  }

  const openLightbox = () => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: 1
    })
  }

  const lightBoxSources = hotelDetail.BookingMedia ? hotelDetail.BookingMedia.map(img => '/api/booking/media/'+img.uuid) : []

  const handleSelectSubscription = () => {
    dispatch(actions.updateOfferPrice())
    history.push('/booking/')
    window.scrollTo(0, 0)
  }

  const buttonBackUrl = '/search/' + searchState?.searchCity?.cityId + '/?nights=' + searchState?.searchNights + '&product=' + searchState?.searchProduct
  const metaTitle = `${hotelDetail?.name || ''} ab ${pricePerNightWithDiscount?.toFixed(0)}€ | Hotels im Abo ${hotelDetail.addressCity} | MyFlexHome`
  const metaDescription = `Ab ${pricePerNightWithDiscount?.toFixed(0)} € pro Nacht im ${hotelDetail?.name || ''}. Flexibel wohnen im Hotel ${hotelDetail.addressCity} - ${hotelDetail.addressDistrict}. Hotel im Abo für Vielreisende. Wohnen auf Zeit ${searchState?.searchCity?.cityName}.`
  const metaKeywords = `Hotel-Abo, ${hotelDetail?.name}, ${hotelDetail.addressCity}, Wohnen auf Zeit, Langzeit Hotel`
  const canonicalUrl = 'https://app.myflexhome.de/hotel/' + searchState?.searchCity?.cityId + '/' + offer?.hotelDetail?.hotelId + '/'

  const HotelDetailView = () => {
    return(
      <div>

        <HelmetProvider>
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords} />
            <link rel="canonical" href={canonicalUrl} />
          </Helmet>
        </HelmetProvider>

        <div className="m-6">
          <ButtonBack target={buttonBackUrl} />
        </div>

        <h1 className="text-xl bg-secondaryBlue text-white text-left rounded-md m-6 mb-0 pl-4">{hotelDetail.name}</h1>

        <div className="flex md:h-96 m-4 mt-1 relative">
          <div className="w-full md:w-8/12 m-2 bg-gray-100 overflow-hidden flex items-center rounded-lg">
            <img
              src={media1url}
              alt={hotelDetail.name}
              className="w-full hover:opacity-80 cursor-pointer"
              onClick={() => openLightboxOnSlide(media1uuid)}
            />
          </div>
          <div className="w-4/12 hidden md:flex flex-col">
            <div className="h-44 m-2 bg-gray-100 overflow-hidden flex items-center rounded-lg">
              <img
                src={media2url}
                alt={hotelDetail.name}
                className="w-full hover:opacity-80 cursor-pointer"
                onClick={() => openLightboxOnSlide(media2uuid)}
              />
            </div>
            <div className="h-44 m-2 bg-gray-100 overflow-hidden flex items-center rounded-lg">
              <img
                src={media3url}
                alt={hotelDetail.name}
                className="w-full hover:opacity-80 cursor-pointer"
                onClick={() => openLightboxOnSlide(media3uuid)}
              />
            </div>
          </div>
          <div
            onClick={openLightbox}
            className="absolute bottom-5 right-5 p-2 rounded bg-white border border-gray-800 z-10 text-xs flex items-center cursor-pointer hover:text-primary hover:border-primary"
          >
            <CropOriginal fontSize="small" /> weitere Bilder
          </div>
        </div>

        <div>
          <div className="ml-6 text-xs text-gray-500 font-light mb-1">{hotelDetail.addressCity} - {hotelDetail.addressDistrict}</div>
        </div>
        <div className="text-2xl mx-6 mb-2 flex items-center">
          <h2>{hotelDetail.name}</h2>
          <RatingStars rating={hotelDetail.ratingStars || 0} classes={'inline-flex relative -top-2 left-1'} />
        </div>


        <div className="flex flex-wrap">
          <div className="flex w-full md:w-1/2 order-last md:order-first">

            <div className="w-full m-6 md:mr-3 p-4 text-left border-gray-200 border-2 rounded-lg">

              {hotelDetail?.BookingDistrict?.DistrictHotels ?
                <div className="mb-6">
                <div className="text-xl font-light text-secondaryBlue mb-4">Stadtteil-Abo</div>
                  {hotelDetail?.BookingDistrict?.description ? <div className="text-sm mb-2">{hotelDetail.BookingDistrict.description}</div> : '' }
                  <div className="text-sm mb-2">In diesem Stadtteil-Abo sind unter anderem folgende Hotels enthalten:</div>

                  {hotelDetail?.BookingDistrict?.DistrictHotels.map(x =>
                    <div key={'stadtteil_'+x.hotelId}>
                      <Link to={'/hotel/' + hotelDetail.BookingCity.cityId + '/' + x.hotelId + '/'}>
                      <div className="flex items-center text-sm mb-2" key={x.uuid}>
                        <div
                          className="w-28 h-20 flex-none bg-cover rounded-lg text-center overflow-hidden hover:opacity-90 relative mr-3"
                          style={{backgroundImage: `url(${x?.BookingMedia?.[0]?.uuid ? '/api/booking/media/'+x?.BookingMedia?.[0]?.uuid : '/hotel-room-icon-03.png'})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                          title={x.name}>
                        </div>
                        <div>
                          <div className="font-bold">{x.name} <RatingStars rating={x.ratingStars || 0} classes={'inline-flex relative -top-1 left-1'} /></div>
                          <div className="text-gray-400">{x.addressStreet}</div>
                          <div className="text-gray-400">{x.addressZip} {x.addressCity}</div>
                        </div>
                      </div>
                      </Link>
                    </div>
                  )}
              </div>: ''}

              <div className="">
                <div className="text-xl font-light text-secondaryBlue mb-4">Highlights</div>
                {hotelDetail.BookingHotelAttributes ? hotelDetail.BookingHotelAttributes.map(x => (x.category === 'super') ? <div key={x.uuid}>
                    <div className="flex items-center text-sm" key={x.uuid}>
                      <img src={'/api/booking/media/'+x.uuid} alt={x.name} className="pt-1 w-8 h-10 mr-3"/>
                      <div>
                        <div className="font-bold">{x.name}</div>
                        <div className="text-gray-400">{x.description}</div>
                      </div>
                    </div>
                  </div> : ''
                ) : ''}
              </div>

              <div className="mt-8 text-sm">
                <div className="text-xl font-light text-secondaryBlue mb-4">Beschreibung</div>
                <div className="resetList" dangerouslySetInnerHTML={{__html: hotelDetail.descriptionDE}} />
              </div>

              <div className="mt-8 text-sm">
                <div className="text-xl font-light text-secondaryBlue mb-4">Ausstattung</div>
                {hotelDetail.BookingHotelAttributes ? hotelDetail.BookingHotelAttributes.map(x => (x.category !== 'super') ? <div key={x.uuid}>
                    <div className="flex items-center text-sm h-10" key={x.uuid}>
                      <img src={'/api/booking/media/'+x.uuid} alt={x.name} className="pt-1 w-8 h-8"/>
                      <div>
                        <div className="">{x.name}</div>
                      </div>
                    </div>
                  </div> : ''
                ) : ''}
              </div>

              <div className="mt-8 text-sm">
                {hotelDetail.checkInTime ? <div className="text-xl font-light text-secondaryBlue mt-8 mb-4">An- und Abreise</div> : ''}
                {hotelDetail.checkInTime ? <div className="mt-6">Ab {hotelDetail.checkInTime} Uhr am Anreisetag Check-In</div> : ''}
                {hotelDetail.checkOutTime ? <div className="">Bis {hotelDetail.checkOutTime} Uhr am Abreisetag Check-Out</div> : ''}
              </div>

              <div className="mt-8 text-sm">
                <div className="text-xl font-light text-secondaryBlue mb-4">Lage</div>
                <p className="mb-2">{hotelDetail.addressCity} - {hotelDetail.addressDistrict}</p>

                <MapsWindowHotelDetail />

                <p className="font-semibold">{hotelDetail.name}</p>
                <p className="">{hotelDetail.addressStreet}</p>
                <p className="">{hotelDetail.addressZip} {hotelDetail.addressCity}</p>
              </div>

              <div className="mt-8 text-sm">
                {hotelDetail.ratingTripadvisor ? <div className="text-xl font-light text-secondaryBlue mb-4">Bewertung</div>  : ''}
                {hotelDetail.ratingTripadvisor ? <div className="text-sm flex flex-wrap justify-start mt-6">
                  <RatingTripadvisor rating={hotelDetail.ratingTripadvisor || 0} uri={hotelDetail.ratingTripadvisorUri} />
                </div> : ''}
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2">
          {hotelDetail.visibleHotelBookingButton
            ? (offer.product !== null)
              ? <>
                <div className="m-6 md:ml-3 p-4 text-left border-secondaryBlue border-2 rounded-lg bg-gray-50">

                  <div className="">
                    <div className={`w-full text-left flex flex-wrap justify-between ${priceStyleWhileUpdating}`}>

                      <div className="w-full order-2 md:w-auto lg:order-1">
                        <div className="text-2xl font-bold">
                          { discountPerNight > 0
                            ? <>
                            <span className="text-red-700 tracking-tight line-through text-lg font-bold mr-2">
                              { pricePerNight?.toFixed(0) } €
                            </span>
                              <span>
                              { pricePerNightWithDiscount?.toFixed(0) } € <span className="text-lg font-light">/ Nacht</span>
                            </span>
                            </>
                            : <>
                            <span>
                              { pricePerNight?.toFixed(0) } € <span className="text-lg font-light">/ Nacht</span>
                            </span>
                            </>
                          }
                          <Tippy theme="light" content={
                            <div>Zu Messezeiten kann es zu Aufpreisen kommen. Aktuelle Messetermine zu diesem Hotel findest du unten aufgelistet.</div>
                          }>
                            <Info className="ml-3 text-gray-500"/>
                          </Tippy>
                        </div>

                        <div className="text-md text-gray-400 font-light">
                          <span className="text-xs">entspricht</span>
                          { discountPerMonth > 0
                            ? <>
                                <span className="tracking-tight text-red-700 line-through ml-1">
                                  { pricePerMonth?.toFixed(0) } €
                                </span>
                                <span className="ml-1">
                                  { pricePerMonthWithDiscount?.toFixed(0) } € / Monat
                                </span>
                              </>
                            : <>
                                <span className="ml-1">
                                  { pricePerMonth?.toFixed(0) } € / Monat
                                </span>
                            </>
                          }
                        </div>

                      </div>

                      <div className="flex gap-x-1 order-1 lg:order-2 lg:ml-1 lg:block lg:gap-0">
                      { savingsInPercent > 5
                        ? <HotelBadgeSavings hotel={{
                            savingsInPercent: offer?.savingsInPercent || 0,
                            pricePerNightStandardRoom: offer?.pricePerNight,
                            pricePerNightWithDiscount: offer?.pricePerNightWithDiscount,
                            avgGooglePrice: offer?.hotelDetail?.avgGooglePrice || 0,
                            googlePriceCheckedAt: offer?.hotelDetail?.googlePriceCheckedAt || 0,
                          }} align={'justify-end my-1'} />
                        : '' }
                      { discountPerNight > 0
                        ? <HotelBadgeDiscount hotel={{
                          standardRoomOfferDiscount: {
                            description: offer?.BookingOfferDiscount?.description,
                            name: offer?.BookingOfferDiscount?.name,
                          }
                          }} align={'justify-end my-1'} />
                        : ''
                      }
                      </div>

                    </div>

                    <div className="text-md border-b border-gray-300 mt-3 pb-4">
                      <div className="flex items-center">
                        <div>Abo {offer?.shareable ? 'ab' : 'mit'}</div>
                        <div className="bg-gray-200 border border-gray-400 rounded px-1 mx-1 hover:bg-gray-300">
                          <Select
                            labelId="nights"
                            className="mx-2"
                            value={subscriptionCategory}
                            onChange={(e) => dispatch(actions.updateOfferSelectionSubscriptionCategory(e.target.value))}
                            displayEmpty
                            disableUnderline
                            variant={'standard'}
                            inputProps={{ 'aria-label': 'Abo-Nächte pro Monat' }}
                          >
                            {hotelDetail.selectableNightsPerMonth.map((x) =>
                              <MenuItem value={x} key={x}>{x}</MenuItem>
                            )}
                          </Select>
                        </div>
                        <div>Nächten im Monat</div>

                        {offer?.shareable
                          ? ''
                          : <Tippy theme="light" content={
                              <div>Du kannst deine Abo Nächte monatlich anpassen.</div>
                            }><Info className="ml-1 md:ml-3 text-gray-500"/></Tippy>
                        }
                      </div>

                      {offer?.shareable
                        ? <div className="mt-2 list-disc text-sm font-light">
                            Dein Nächte-Paket ist als monatliches Kontingent zu verstehen. Bei höherem Bedarf kannst du beliebig viele Nächte dazu buchen.
                            <Tippy theme="light" content={
                              <div>
                                Ungenutzte Nächte nimmst du in den nächsten Monat mit. Monatlich kannst zwischen dem "ab 10 Nächte" oder "ab 30 Nächte Abo" wechseln.
                              </div>
                            }><Info className="ml-1 text-gray-500"/></Tippy>
                          </div>
                        : ''
                      }

                      {offer?.shareable
                        ? <BusinessSubscriptionHelp />
                        : ''
                      }

                    </div>

                    <div className="pt-4">
                      <div className="text-xl font-light text-secondaryBlue mb-2">Zimmerkategorie</div>

                      <div className="flex">
                        { rooms.map(room => {
                          const buttonClasses = (subscriptionRoom === room.BookingRoomCategory.shortcode)
                            ? 'btn bg-gray-200 border border-gray-400 hover:border-primary rounded p-1 w-full'
                            : 'btn bg-gray-100 border border-gray-300 hover:border-primary rounded p-1 w-full text-gray-500 hover:text-black'
                          return(
                            <div key={room.uuid} className="w-56 pr-3">
                              <button
                                className={buttonClasses}
                                key={room.uuid}
                                onClick={()=> dispatch(updateOfferSelectionSubscriptionRoom(room.BookingRoomCategory.shortcode))}
                              >
                                {room.name}
                              </button>
                              <div className="text-sm mt-1 resetList" dangerouslySetInnerHTML={{__html: room.description}} />
                            </div>
                          )
                        })}
                      </div>

                    </div>

                    <div className="pt-4 border-b border-gray-300 pb-4">
                      <div className="flex">
                        <div className="text-xl font-light text-secondaryBlue mb-2">Abo-Laufzeit</div>
                        <Tippy theme="light" content={
                          <div>Dein Abo verlängert sich monatlich</div>
                        }><Info className="ml-3 text-secondaryBlue"/></Tippy>
                      </div>

                      <div className="flex">
                        { rooms.map(room => {
                          const offers = room?.BookingOffers || []
                          return offers.map(o => {
                            const buttonClasses = (subscriptionDuration === o.BookingSubscriptionDuration.months)
                              ? 'btn bg-gray-200 border border-gray-400 hover:border-primary rounded p-1 w-full'
                              : 'btn bg-gray-100 border border-gray-300 hover:border-primary rounded p-1 w-full text-gray-500 hover:text-black'
                            if(
                              (o.BookingSubscriptionCategory.nightsPerMonth === subscriptionCategory)
                              && (subscriptionRoom === room.BookingRoomCategory.shortcode)
                            ) {
                              return(
                                <div key={o.description} className="w-56 pr-3">
                                  <button
                                    className={buttonClasses}
                                    key={room.uuid+'o'}
                                    onClick={()=> dispatch(updateOfferSelectionSubscriptionDuration(o.BookingSubscriptionDuration.months))}
                                  >
                                    {o.BookingSubscriptionDuration.name}
                                  </button>
                                  <div className="text-sm mt-1 resetList" dangerouslySetInnerHTML={{__html: o.description}} />
                                </div>
                              )
                            } else {
                              return null
                            }
                          })
                        }) }
                      </div>
                    </div>
                  </div>

                  <div className="text-base my-5 pb-4">
                    <div className="text-xl font-light text-secondaryBlue mb-2">MyFlexHome Abo Konditionen</div>
                    <div className="text-sm resetList" dangerouslySetInnerHTML={{__html: hotelDetail.subscriptionTerms}} />
                  </div>

                  <div className="text-center mt-5">
                    <button
                      className="text-lg bg-primary text-white hover:bg-secondaryBlue rounded p-2 w-64"
                      onClick={handleSelectSubscription}
                    >
                      Hotel Abo wählen
                    </button>
                    <div className="text-sm text-gray-400 mt-2">Wähle im nächsten Schritt deine Übernachtungen.</div>
                    <div className="text-sm text-gray-400">Du musst noch nichts bezahlen.</div>
                  </div>

                </div>

                <div className="ml-3 mr-6 p-4">
                  <div className="text-lg mb-2">FAQ</div>
                  <HotelDetailsFAQ />
                  <div className="text-sm underline text-right"><a
                    href="https://www.myflexhome.de/faq/" target="_blank" rel="noreferrer">zu den FAQs</a>
                  </div>
                  {hotelDetail.BookingSpecialDates?.length ? <HotelDetailsSpecialDates /> : ''}
                </div>
                </>
              : <div className="m-6 md:ml-3 p-4 text-left border-secondaryBlue border-2 rounded-lg bg-gray-50">
                Das Hotel {hotelDetail?.name} ist mit den gewählten Such-Parametern nicht verfügbar.
                <br/><br/>
                <Link
                  to={'/hotel/'+searchState?.searchCity?.cityId+'/'+hotelDetail?.hotelId+'/'}
                  className="text-secondaryBlue hover:text-primary underline">Hotel mit verfügbarer Abo-Konfiguration aufrufen.</Link>
                </div>
            : <div className="m-6 md:ml-3 p-4 text-left border-secondaryBlue border-2 rounded-lg bg-gray-50">
                Dieses Hotel ist aktuell nicht im Abo verfügbar.
              </div>
          }
          </div>

        </div>

      </div>
    )
  }

  return(
    <div className="container max-w-screen-lg mx-auto mb-6">
      { isLoading ? <div className="flex justify-center my-44"><LoadingSpinner /></div> : <HotelDetailView />}
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={lightBoxSources}
        slide={lightboxController.slide}
      />
    </div>
  )
}


export default HotelDetails
