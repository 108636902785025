import React from 'react';
import * as actions from '../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import ListBoxCity from "./ListBoxCity";
import ListBoxNights from "./ListBoxNights";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";
import SearchBarBusinessSwitch from "./SearchBarBusinessSwitch";

const SearchBar = () => {

  const dispatch = useDispatch()

  const cityList = useSelector(state => state.search.cityList)
  const nightList = useSelector(state => state.search.nightListCurrentProduct)
  const inputCity = useSelector(state => state.search.inputCity)
  const inputNights = useSelector(state => state.search.inputNights)
  const searchProduct = useSelector(state => state.search.searchProduct)
  const isBusiness = (searchProduct === 'business')

  const handleCityChange = (value) => {
    dispatch(actions.updateInputCity(value.cityId))
  }

  const handleNightsChange = (value) => {
    dispatch(actions.updateInputNights(value.n))
  }

  const handleSearchButton = () => {
    dispatch(actions.updateSearchCity(inputCity.cityId))
    dispatch(actions.updateSearchNights(inputNights))
    dispatch(actions.setSearchUrl())
  }

  return (
    <div id="search" className="-mt-1">

      <div className="flex items-center justify-center">
        <SearchBarBusinessSwitch />
      </div>

      <div className="flex justify-center">

        <div className="flex flex-wrap items-center xl:justify-center bg-gray-100 rounded-lg p-0 shadow-md z-10">

          <div className="bg-gray-100 py-2 px-3 mx-1 flex items-center w-full sm:w-48">
            <ListBoxCity data={cityList} searchCity={inputCity} setSearchCity={handleCityChange} />
          </div>

          <div className="bg-gray-100 px-3 mx-1 flex items-center w-full sm:w-48">
            <ListBoxNights data={nightList} searchNights={inputNights} addFrom={isBusiness} setSearchNights={handleNightsChange} />
          </div>

          <div className="w-full sm:w-40 ml-2 flex flex-col my-0 mr-4">
            <button
              className="btn flex justify-center items-center rounded-lg text-white bg-primary hover:bg-secondaryBlue mx-1 my-1 px-3 py-2 sm:px-4 w-full noShadow focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-orange-300"
              onClick={handleSearchButton}
            ><MagnifyingGlassIcon className="w-5 h-5" /><span className="font-bold ml-2">Suchen</span></button>
          </div>

        </div>

      </div>

    </div>
  )

}

export default SearchBar
