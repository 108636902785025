import StepperBar from '../components/StepperBar';
import React, {useEffect} from 'react';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import RatingStars from '../components/RatingStars';
import {compareDateToArray, dateArrayFromRange, dateXDaysFromDate, formatDateWithMonth} from "../functions/dateHelper";
import {format} from "date-fns";
import deLocale from "date-fns/locale/de";
import BusinessSubscriptionHelp from "../components/BusinessSubscriptionHelp";


const Summary = () => {

  const history = useHistory()

  /* redux states */
  const hotelDetail = useSelector(state => state.offer.hotelDetail)
  const subscriptionRoomUuid = useSelector(state => state.offer.subscriptionRoomUuid)
  const subscriptionCategory = useSelector(state => state.offer.subscriptionCategory)
  const subscriptionRoom = useSelector(state => state.offer.subscriptionRoom)
  const subscriptionRoomDetails = hotelDetail?.BookingRooms?.filter(room => (subscriptionRoom === room.BookingRoomCategory.shortcode))[0] || {}
  const subscriptionDuration = useSelector(state => state.offer.subscriptionDuration)
  const subscriptionStartDate = useSelector(state => state.offer.subscriptionStartDate)
  const selectedNights = useSelector(state => state.offer.selectedNights)
  const specialDateRanges = hotelDetail?.BookingSpecialDates || []
  const pricePerMonth = useSelector(state => state.offer.pricePerMonth) || 0
  const pricePerNight = useSelector(state => state.offer.pricePerNight) || 0
  const pricePerMonthWithDiscount = useSelector(state => state.offer.pricePerMonthWithDiscount) || 0
  const pricePerNightWithDiscount = useSelector(state => state.offer.pricePerNightWithDiscount) || 0
  const discountPerNight = useSelector(state => state.offer.discountPerNight) || null
  const discountPerMonth = useSelector(state => state.offer.discountPerMonth) || null
  const offerDiscount = useSelector(state => state.offer?.BookingOfferDiscount) || null
  const surchargeSum = useSelector(state => state.offer.surchargeSum) || 0
  const registration = useSelector(state => state.offer.registration)
  const paymentProcessor = useSelector(state => state.offer.processor)
  const product = useSelector(state => state.offer.product)

  /* check for preconditions, otherwise start from scratch */
  useEffect(() => {
    if (pricePerNight === 0) {
      history.push('/search/muenchen/?nights=4')
      window.scrollTo(0, 0)
    }
  }, [history, pricePerMonth, pricePerNight])

  let mediaUrl = '/hotel-room-icon-03.png'
  if (Array.isArray(hotelDetail.BookingMedia) && hotelDetail.BookingMedia.length) {
    const mediaUuid = hotelDetail.BookingMedia.filter(picture => picture.position === '1')[0]?.uuid || hotelDetail.BookingMedia[0]?.uuid
    if(mediaUuid) mediaUrl = '/api/booking/media/'+mediaUuid
  }

  const surchargeValue = (day) => {
    let result = ''
    specialDateRanges.forEach(rangeObj => {
      const rangeArr = dateArrayFromRange(rangeObj.dateFrom, rangeObj.dateTo)
      const surcharge = rangeObj?.BookingSpecialDateSurcharges?.filter(surcharge => surcharge.BookingRoom.uuid === subscriptionRoomUuid)?.[0]?.surcharge
      if(surcharge && compareDateToArray(day, rangeArr)) {
        result = <span>+{surcharge.toFixed(2).replace('.',',')} €</span>
      }
    })
    return result
  }

  return (
    <div className="container max-w-screen-lg mx-auto mb-10">
        <div className="mx-3 md:w-10/12 md:mx-auto">
          <StepperBar active={3} />

          <div className="text-center text-xl font-light mt-10 text-gray-400">Zusammenfassung</div>

          <div className="rounded-lg shadow-lg border border-gray-500 p-3 my-4">

            {product === 'business'
              ? <div className="text-2xl font-light mb-3">Hotel-Abo ab <span className="font-semibold">{subscriptionCategory}</span> Nächten / Monat</div>
              : <div className="text-2xl font-light mb-3">Hotel-Abo mit <span className="font-semibold">{subscriptionCategory}</span> Nächten / Monat</div>
            }

            <div className="text-sm border-b border-gray-300 pb-2">Startdatum: {formatDateWithMonth(subscriptionStartDate)}</div>

            <div className="flex flex-wrap border-b border-gray-300 pb-2">
              <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
                Hotel
              </div>
              <div className="w-full md:w-9/12 pt-3 text-sm">
                <div className="text-xs text-gray-500 font-light mb-1">{hotelDetail.addressCity} - {hotelDetail.addressDistrict}</div>
                <div className="flex">
                  <div className="font-regular mr-1">{hotelDetail.name}</div>
                  <RatingStars rating={hotelDetail.ratingStars || 0} />
                </div>
                <p className="">{hotelDetail.addressStreet}</p>
                <p className="">{hotelDetail.addressZip} {hotelDetail.addressCity}</p>

                <div className="mt-2 flex flex-wrap">
                  {hotelDetail.BookingHotelAttributes ? hotelDetail.BookingHotelAttributes.map(x => (x.category === 'super') ? <div key={x.uuid}>
                      <div className="flex items-center text-sm" key={x.uuid}>
                        <img src={'/api/booking/media/'+x.uuid} alt={x.name} className="pt-1 w-6 h-7 mr-1"/>
                        <div className="font-light mr-4">{x.name}</div>
                      </div>
                    </div> : ''
                  ) : ''}
                </div>

                <div
                  className="my-2 h-28 w-44 bg-cover rounded text-center overflow-hidden"
                  style={{backgroundImage: `url(${mediaUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                  title={hotelDetail.name}
                >
                  <div className=""> </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap border-b border-gray-300 pb-2">
              <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
                  Zimmerkategorie
              </div>
              <div className="w-full md:w-9/12 pt-3 text-sm">
                <div className="font-regular">{subscriptionRoomDetails.name}</div>
                <div className="text-sm font-light mt-2 resetList" dangerouslySetInnerHTML={{__html: subscriptionRoomDetails.description}} />
              </div>
            </div>

            <div className="flex flex-wrap border-b border-gray-300 pb-2">
              <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
                Abo-Laufzeit
              </div>
              <div className="w-full md:w-9/12 pt-3 text-sm">
                <div className="font-regular">{subscriptionDuration} Monat{ subscriptionDuration > 1 ? 'e' : ''}</div>
                <div className="font-light mt-2">Du kannst dein Abo jederzeit online ändern oder kündigen.</div>
              </div>
            </div>

            <div className="flex flex-wrap border-b border-gray-300 pb-2">
              <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
                Konditionen
              </div>
              <div className="w-full md:w-9/12 pt-3 text-sm">
                <div className="font-regular">MyFlexHome Abo Konditionen:</div>
                <div className="text-sm font-light mt-2 resetList" dangerouslySetInnerHTML={{__html: hotelDetail.subscriptionTerms}} />
                <div>{product === 'business'
                  ? <BusinessSubscriptionHelp help={false}/>
                  : ''
                }</div>
              </div>
            </div>

            <div className="flex flex-wrap border-b border-gray-300 pb-3">
              <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
                Kundendaten
              </div>
              <div className="w-full md:w-9/12 pt-3 text-sm font-light">
                <div>
                  <p>{registration?.firstName} {registration?.lastName}</p>
                  <p>{registration?.company}</p>
                  <p>{registration?.addressStreet}</p>
                  <p>{registration?.addressZip} {registration?.addressCity}</p>
                  <p>{registration?.addressCountry}</p>
                </div>
              </div>
              {registration?.billingStreet || registration?.billingLastName
                ? <>
                    <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
                      Abweichende Rechnungsadresse
                    </div>
                    <div className="w-full md:w-9/12 pt-3 text-sm font-light">
                      <p>{registration?.billingFirstName} {registration?.billingLastName}</p>
                      <p>{registration?.billingCompany}</p>
                      <p>{registration?.billingStreet}</p>
                      <p>{registration?.billingZip} {registration?.billingCity}</p>
                      <p>{registration?.billingCountry}</p>
                    </div>
                  </>
                : ''}
            </div>

            {selectedNights.length > 0
              ? <div className="flex flex-wrap border-b border-gray-300 pb-2">
                  <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
                    Gewählte Nächte
                  </div>
                  <div className="w-full md:w-9/12 pt-3 text-sm font-light">
                    <div className="flex flex-col">
                      {selectedNights.length > 0
                        ? selectedNights.map((day) =>
                          <div
                            className="flex flex-row justify-between items-center border border-secondaryBlue text-secondaryBlue rounded p-1 mb-2 w-64"
                            key={Date.parse(day)}
                          >
                            <div className="ml-2">
                              {
                                format(day, 'eee dd.MM.', {locale: deLocale})
                                + ' - '
                                + format(dateXDaysFromDate(day,1), 'dd.MM.yyyy', {locale: deLocale})
                              }
                            </div>
                            <div className="font-normal text-secondaryBlue">
                              {
                                surchargeValue(day)
                              }
                            </div>
                          </div>
                        )
                        : ''
                      }
                    </div>
                  </div>
                </div>
              : ''}

            <div className="flex flex-wrap">
              <div className="w-full md:w-3/12 pt-3 text-sm font-bold">
                  Kosten
              </div>
              <div className="w-full md:w-9/12 pt-3 text-sm">

                <div className="flex flex-wrap justify-between items-end">
                  <div>
                    <div className="font-regular">Monatlich</div>
                    { discountPerNight > 0
                      ? <>
                        <div className="font-light mt-1 text-red-800">Aktion "{offerDiscount?.name}"</div>
                        {(discountPerMonth > 0 && offerDiscount?.numberOfMonths > 0)
                          ? <>
                              <div className="font-light">1. bis {offerDiscount?.numberOfMonths}. Monat {pricePerMonthWithDiscount.toFixed(2).replace('.',',')} €</div>
                              <div className="font-light">Ab {offerDiscount?.numberOfMonths + 1}. Monat {pricePerMonth?.toFixed(2)?.replace('.',',')} €</div>
                            </>
                          : ''}
                      </>
                      : '' }
                    {discountPerNight > 0
                      ? <div className="font-light">Hotel-Abo <span
                        className="ml-2 text-gray-500">(<span className="text-red-800 line-through">{
                          pricePerNight.toFixed(2).replace('.', ',')
                        } €</span> &nbsp; {
                          pricePerNightWithDiscount.toFixed(2).replace('.', ',')
                        } € / Nacht)</span>
                      </div>
                      : <div className="font-light">Hotel-Abo <span
                        className="ml-2 text-gray-500">({pricePerNight.toFixed(2).replace('.', ',')} € / Nacht)</span>
                      </div>
                    }


                  </div>
                  <div className="text-lg mr-3">
                    {discountPerMonth > 0
                      ? <div className="text-red-800 line-through">
                        <span className="text-sm">{pricePerMonth?.toFixed(2)?.replace('.',',')} €</span>
                      </div>
                      : ''}
                    <div className="text-lg">{pricePerMonthWithDiscount.toFixed(2).replace('.',',')} €</div>
                  </div>
                </div>

                {
                  (surchargeSum > 0)
                  ? <div className="flex flex-wrap justify-between items-end mt-4">
                      <div>
                        <div className="font-regular">Einmalig</div>
                        <div className="font-light">Aufpreis für ausgewählte Nächte</div>
                      </div>
                      <div className="text-lg mr-3">{surchargeSum.toFixed(2).replace('.',',')} €</div>
                    </div>
                  : ''
                }
              </div>
            </div>

          </div>

          {/*
          <div className="mb-4">
            <div className="text-center font-bold mb-2">Zahlungsmethode wählen</div>
            <div className="flex justify-center space-x-2">
              <div className="flex justify-center items-center border-2 border-gray-200 p-1 rounded">
                <div className="flex justify-center bg-gray-200 hover:bg-gray-100 w-48 h-20 p-2">Kreditkarte</div></div>
              <div className="flex justify-center items-center border-2 border-gray-200 p-1 rounded">
                <div className="flex flex-col bg-gray-200 hover:bg-gray-100 w-48 h-20 p-2">
                  <div>Rechnung</div>
                  <div>Test</div>
                </div>
              </div>
            </div>
          </div>
          */}

          <div className="text-center mx-auto mt-2">
          { (paymentProcessor === 'STRIPE')
            ? <form action="/api/booking/checkout/payment" method="POST">
                <button
                  className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-3 w-64"
                  type="submit"
                >
                  Zur Zahlung
                </button>
              </form>
            : <form action="/api/booking/checkout/purchaseOrderDirect" method="POST">
              <button
                className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-3 w-64"
                type="submit"
              >
                Abo zahlungspflichtig bestellen
              </button>
            </form>
          }
          </div>

        </div>
    </div>
  )
}

export default Summary
