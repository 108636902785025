import * as actionTypes from './actionTypes'
import axios from '../../axios.config'
import * as actions from "./index";
import {history} from "../../index";


/*
 * GET all available cities
 */
export const getCities = (selected=null) => {
  return (dispatch, getState) => {
    if(getState().search.cityList.length < 2) {
      // no data in local store - fetch list
      dispatch(getCitiesStart())
      axios.get('/booking/search/cities')
        .then ( response => {
          dispatch(getCitiesSuccess(response.data))
          if(selected) {
            dispatch(updateInputCity(selected))
            dispatch(updateSearchCity(selected))
          }
        })
        .catch(error => {
          dispatch(getCitiesFail(error))
        })
    } else {
      // city list in local store - just update gui
      dispatch(updateInputCity(selected))
      dispatch(updateSearchCity(selected))
    }
  }
}

const getCitiesStart = () => {
  return {
    type: actionTypes.GET_CITIES_START,
  }
}

const getCitiesSuccess = (res) => {
  return {
    type: actionTypes.GET_CITIES_SUCCESS,
    res: res,
  }
}

const getCitiesFail = (error) => {
  return {
    type: actionTypes.GET_CITIES_FAIL,
    error: error,
  }
}


/*
 * GET all nights
 */
export const getNights = () => {
  return (dispatch, getState) => {
    if(getState().search.nightList.length < 2) {
      // no data in local store - fetch list
      dispatch(getNightsStart())
      axios.get('/booking/search/nights')
        .then ( response => {
          dispatch(getNightsSuccess(response.data))
        })
        .catch(error => {
          dispatch(getNightsFail(error))
        })
    }
  }
}

const getNightsStart = () => {
  return {
    type: actionTypes.GET_NIGHTS_START,
  }
}

const getNightsSuccess = (res) => {
  return {
    type: actionTypes.GET_NIGHTS_SUCCESS,
    res: res,
  }
}

const getNightsFail = (error) => {
  return {
    type: actionTypes.GET_NIGHTS_FAIL,
    error: error,
  }
}


/*
 * GET all hotels by cityCode and nightsPerMonth
 */
export const getAllHotels = (cityCode, nightsPerMonth=4, product, sort, order, filter, cache) => {
  return dispatch => {
    dispatch(getAllHotelsStart())
    axios.get('/booking/hotel/byCity/'+cityCode+'?nightsPerMonth='+nightsPerMonth+'&product='+product
      +'&sort='+sort+'&order='+order+'&filter='+filter+(cache ? '&cache='+cache: ''))
      .then ( response => {
        dispatch(getAllHotelsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getAllHotelsFail(error))
      })
  }
}

const getAllHotelsStart = () => {
  return {
    type: actionTypes.GET_ALL_HOTELS_START,
  }
}

const getAllHotelsSuccess = (res) => {
  return {
    type: actionTypes.GET_ALL_HOTELS_SUCCESS,
    res: res,
  }
}

const getAllHotelsFail = (error) => {
  return {
    type: actionTypes.GET_ALL_HOTELS_FAIL,
    error: error,
  }
}


/*
 * GET hotel details by hotelId
 */
export const getHotelDetail = (id, product='',nightsPerMonth=4) => {
  return dispatch => {
    dispatch(getHotelDetailStart())
    axios.get('/booking/hotel/byId/'+id+'?nightsPerMonth='+nightsPerMonth+'&product='+product)
      .then ( response => {
        dispatch(getHotelDetailSuccess(response.data))
        dispatch(actions.updateOfferPrice())
      })
      .catch(error => {
        dispatch(getHotelDetailFail(error))
      })
  }
}

const getHotelDetailStart = () => {
  return {
    type: actionTypes.GET_HOTEL_DETAIL_START,
  }
}

const getHotelDetailSuccess = (res) => {
  return {
    type: actionTypes.GET_HOTEL_DETAIL_SUCCESS,
    res: res,
  }
}

const getHotelDetailFail = (error) => {
  return {
    type: actionTypes.GET_HOTEL_DETAIL_FAIL,
    error: error,
  }
}


/*
 * UPDATE GUI input field - search city
 */
export const updateInputCity = (city) => {
  return (dispatch, getState) => {
    const cityObj = getState().search.cityList.find(obj => obj.cityId === city) || getState().search.cityList[0]
    dispatch({
      type: actionTypes.UPDATE_INPUT_CITY,
      res: cityObj,
    })
  }
}


/*
 * UPDATE GUI input field - search nights
 */
export const updateInputNights = (nights) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_INPUT_NIGHTS,
      res: nights,
    })
  }
}


/*
 * UPDATE GUI input field - search sort
 */
export const updateInputSort = (value) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_INPUT_SORT,
      res: value,
    })
  }
}


/*
 * UPDATE GUI input field - search filter
 */
export const updateInputFilter = (value) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_INPUT_FILTER,
      res: value,
    })
  }
}


/*
 * RESET GUI input field - search filter
 */
export const resetInputFilter = (value) => {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_INPUT_FILTER,
      res: value,
    })
  }
}



/*
 * UPDATE GUI state - search city
 */
export const updateSearchCity = (city) => {
  return (dispatch, getState) => {
    const noCityFound = {
      cityId: null,
      cityName: city,
      cityCenterLat: 48.1375377882454,
      cityCenterLong: 11.575768313666547,
      zoomLevel: 11
    }
    const cityObj = getState().search.cityList.find(obj => obj.cityId === city) || noCityFound
    dispatch({
      type: actionTypes.UPDATE_SEARCH_CITY,
      res: cityObj,
    })
  }
}


/*
 * UPDATE GUI state - search nights
 */
export const updateSearchNights = (nights) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_SEARCH_NIGHTS,
      res: nights,
    })
  }
}


/*
 * UPDATE GUI state - search sort
 */
export const updateSearchSort = (criteria) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_SEARCH_SORT,
      res: criteria,
    })
  }
}


/*
 * UPDATE GUI state - search filter
 */
export const updateSearchFilter = (criteria) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_SEARCH_FILTER,
      res: criteria,
    })
  }
}


/*
 * UPDATE GUI state - search product (single, business, ..)
 */
export const updateSearchProduct = (criteria) => {

  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.UPDATE_SEARCH_PRODUCT,
      res: criteria,
    })

  }
}


export const setSearchUrl = () => {
  return (dispatch, getState) => {
    const city = getState().search.searchCity.cityId
    const nights = getState().search.searchNights
    const product = getState().search.searchProduct
    const sort = getState().search.searchSort
    const order = getState().search.searchOrder
    const filter = getState().search.searchFilter

    const sortString = (sort||filter) ? '&sort='+sort+'&order='+order+'&filter='+filter : ''
    const url = '/search/'+city+'/?nights='+nights+'&product='+product+sortString

    const payload = {
      city: city,
      nights: nights,
      product: product,
      sort: sort,
      order: order,
      filter: filter,
    }
    axios.post('/booking/search',payload)

    window.scrollTo(0, 0)
    history.push(url)

    dispatch({
      type: actionTypes.SET_SEARCH_URL,
      res: url,
    })
  }
}
