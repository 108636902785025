import BusinessBuilding from "../assets/svg/BusinessBuilding";
import Tippy from "@tippyjs/react";
import {Info} from "@mui/icons-material";
import React from "react";

const BusinessSubscriptionHelp = ({help=true}) => {
  return(
    <div className="mt-6 flex items-center text-teal-700">
      <BusinessBuilding className="w-7 h-7 mr-1" />
      <div>
        <div className="font-bold">
          Business Abo
          {help
            ? <Tippy theme="light" content={
                <div>
                  Das Abo ist für mehrere Personen nutzbar. <br/>
                  Als Business Abo Administrator kannst du beliebig viele Personen einladen.
                  Du entscheidest, ob du für andere direkt buchst oder für sie den Self-Service freischaltest.
                </div>
                }><Info className="ml-1 text-teal-700"/>
              </Tippy>
            : ''
          }
        </div>
        <div className="font-light text-sm">
          Uneingeschränkt nutzbar für mehrere Personen
        </div>
      </div>
    </div>
  )
}

export default BusinessSubscriptionHelp