import React, {useState} from 'react';
import {Marker, Popup} from 'react-map-gl';
import {useHistory} from 'react-router';
import RatingStars from './RatingStars';
import {useSelector} from "react-redux";
import logoInvers from "../assets/images/logo_invers_128.png";
import MapsPinDistrict from "./MapsPinDistrict";
import MapsPinSingle from "./MapsPinSingle";
import {createUrlWithParams} from "../functions/url";

export const MapsMarker = (props) => {

  const history = useHistory()

  const {pinType, hotel, highlightHotel, setHighlightHotel} = props

  const isLoggedIn = useSelector(state => state.login.isLoggedIn)
  const searchCity = useSelector(state => state.search.searchCity)
  const product = useSelector(state => state.search.searchProduct)
  const [showMarkerPopup, setShowMarkerPopup] = useState(false)

  const membersOnly = hotel.membersOnly
  const priceHidden = membersOnly && !isLoggedIn
  const baseUrl = '/hotel/' + searchCity.cityId + '/' + hotel.hotelId + '/'
  const detailUrl = createUrlWithParams(baseUrl, {nights: hotel.nightsPerMonth, product: product})

  const enterPin = () => {
    setHighlightHotel(hotel.hotelId)
    setShowMarkerPopup(true)
  }
  const leavePin = () => {
    setHighlightHotel(null)
    setShowMarkerPopup(false)
  }

  const CustomPopup = ({lat, long, hotel}) => {
    return (
      <Popup
        latitude={lat}
        longitude={long}
        onClose={leavePin}
        closeButton={false}
        closeOnClick={false}
        offset={[-3, -40]}
        className="z-50"
      >
        <div>
          <div className="flex text-lg w-56 flex-wrap">{hotel.name} <RatingStars rating={hotel.ratingStars || 0} size="h-3 w-3" /></div>
          <div className="text-xs">{hotel.addressStreet}</div>
          <div className="text-xs">{hotel.addressZip} {hotel.addressCity}</div>
          {hotel?.BookingDistrict?.description ? <div className="text-xs">{hotel.BookingDistrict.description}</div> : '' }
        </div>
      </Popup>
    )
  }

  if(hotel) {
    const lat = parseFloat(hotel.locationLat)
    const long = parseFloat(hotel.locationLong)
    const highlight = (hotel.hotelId === highlightHotel) || showMarkerPopup
    const labelClass = highlight
      ? 'bg-white text-md rounded-lg p-1 border border-secondaryBlue'
      : 'bg-white text-md rounded-lg p-1 border border-primary'

    if(lat && long) {
      return (
        <div>
          <Marker latitude={lat} longitude={long} offset={[0, 0]} className="z-10">
            <div
              className="flex flex-col justify-center text-center items-center cursor-pointer"
              onClick={() => {
                if(!priceHidden) {
                  history.push(detailUrl)
                }
              }}
            >
              <div
                className={highlight ? 'grow' : ''}
                onMouseEnter={enterPin}
                onMouseLeave={leavePin}
              >
                {pinType === 'single'
                  ? highlight ? <MapsPinSingle size={40} fill="#086381" stroke="#fff" /> : <MapsPinSingle stroke="#fff" size={40}/>
                  : ''}
                {pinType === 'district'
                  ? highlight
                    ? <MapsPinDistrict size={44} fill="#086381" stroke="#fff" />
                    : <MapsPinDistrict size={44} fill="#25A9D5" stroke="#fff"/>
                  : ''}
              </div>

              {hotel.pricePerNightStandardRoom && !priceHidden
                ? <div
                    className={labelClass}
                    onMouseEnter={enterPin}
                    onMouseLeave={leavePin}
                  >
                  {hotel.discountPerNight > 0
                    ? <div>
                        <div className="text-red-700 line-through">{hotel.pricePerNightStandardRoom.toFixed(0)} €</div>
                        <div className="font-bold">{hotel.pricePerNightWithDiscount.toFixed(0)} €</div>
                      </div>
                    : <div className="font-bold">{hotel.pricePerNightStandardRoom.toFixed(0)} €</div>}
                  </div>
                : ''
              }
              {priceHidden
                ? <div
                    className="bg-membersOnly text-white text-xs rounded-lg p-1 flex"
                    onMouseEnter={enterPin}
                    onMouseLeave={leavePin}
                  >
                    <img src={logoInvers} width="16" height="16" alt="Members Only" /><span className="ml-1">Members only</span>
                  </div>
                : ''
              }
            </div>

          </Marker>
          {showMarkerPopup ? <CustomPopup lat={lat} long={long} hotel={hotel} /> : ''}
        </div>
      )
    }

  }

}
