import * as React from 'react';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    marginTop: 4,
    marginBottom: 4,
  },
  fontSize: 12,
  minHeight: 30,
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  fontSize: 12,
}))


const HotelDetailsFAQ = () => {
  const [expanded, setExpanded] = React.useState('panel0');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div>
      <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
        <AccordionSummary aria-controls="panel0d-content" id="panel0d-header">
          Wann verlängert sich mein Abo?
        </AccordionSummary>
        <AccordionDetails>
          Dein Abo verlängert sich am letzten Tag deines aktuellen Abo-Zeitraums automatisch.
          Du kannst dein Abo jederzeit bis einen Tag vor der Verlängerung kündigen oder pausieren,
          sofern du keine offenen Buchungen im nächsten Zeitraum hast.
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          Was passiert wenn ich nicht alle Abo-Nächte nutze?
        </AccordionSummary>
        <AccordionDetails>
          Solltest du einmal nicht alle deine Nächte in einem Abo-Monat nutzen, kannst du die ungenutzten Nächte
          mit in deinen nächsten Abo-Zeitraum nehmen. Bitte beachte, dass du nur maximal so viele Nächte
          mitnehmen kannst, wie in deinem Abo enthalten sind.
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          Was ist die Best-Preis-Garantie?
        </AccordionSummary>
        <AccordionDetails>
          MyFlexHome garantiert dir dass deine Übernachtungen im Abo günstiger als Einzelbuchungen sind.
          Findest du nach deiner Buchung auf der offiziellen Hotel Homepage einen günstigeren Preis für deine gebuchten
          Übernachtungen, bekommst du von uns die Differenz erstattet. Mehr Informationen findest du
          unter <a href="https://www.myflexhome.de/bestpreisgarantie/" target="_blank" rel="noreferrer" className="underline">Bestpreisgarantie</a>.
        </AccordionDetails>
	  </Accordion>
    </div>
  )
}

export default HotelDetailsFAQ
