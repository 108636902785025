import {Switch} from "@headlessui/react";
import React from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";
import {updateCurrentUrlParams} from "../functions/url";
import {Info} from "@mui/icons-material";
import Tippy from "@tippyjs/react";


const SearchBarBusinessSwitch = () => {

  const searchProduct = useSelector(state => state.search.searchProduct)
  const business = (searchProduct === 'business')
  const history = useHistory()

  const handleSwitch = (e) => {
    if(e) {
      updateCurrentUrlParams(history, {
        'nights': 0,
        'product': 'business',
      })
    } else {
      updateCurrentUrlParams(history, {
        'nights': 4,
        'product': 'single'
      })
    }
  }

  return(
    <div className="flex px-3 pt-2 pb-1 -mt-3 rounded-b-lg items-center">
      <span className={`mr-1 text-sm sm:text-xs cursor-pointer ${business ? 'text-gray-400' : 'text-gray-900'}`} onClick={() => handleSwitch(false)}>Für dich</span>
      <Switch
        checked={business}
        onChange={handleSwitch}
        className={`-mt-1 bg-gray-500 relative inline-flex h-[21px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
        <span className="sr-only">Für Unternehmen</span>
        <span
          aria-hidden="true"
          className={`${business ? 'translate-x-4' : 'translate-x-0'}
            pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
      <span className={`ml-1 text-sm sm:text-xs cursor-pointer ${business ? 'text-gray-900' : 'text-gray-400'}`} onClick={() => handleSwitch(true)}>Für Unternehmen</span>
      <Tippy theme="light" content={
        <div>
          Das Business Abo ist für mehrere Personen nutzbar. <br/>
        </div>
      }><Info className="ml-1 text-gray-400 scale-110 sm:scale-90"/>
      </Tippy>
    </div>
  )
}

export default SearchBarBusinessSwitch