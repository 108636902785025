import * as actionTypes from '../actions/actionTypes';
import {dateXDaysFromDate, getDateAsUTC, inThreeDays, tomorrow} from "../../functions/dateHelper";
import {max, min} from 'date-fns';

const initialState = {
  hotelDetail: {},
  subscriptionCategory: 4,
  subscriptionRoom: 'standard',
  subscriptionRoomUuid: '',
  subscriptionDuration: 1,
  subscriptionStartDate: getDateAsUTC(inThreeDays()),
  bookingCalendarFrom: getDateAsUTC(inThreeDays()),
  bookingCalendarTo: getDateAsUTC(dateXDaysFromDate(tomorrow(), 90)),
  offerID: '',
  product: '',
  shareable: '',
  selectedNights: [],
  pricePerMonth: 0,
  pricePerNight: 0,
  pricePerMonthWithDiscount: 0,
  pricePerNightWithDiscount: 0,
  discountPerMonth: 0,
  discountPerNight: 0,
  BookingOfferDiscount: {},
  savingsInPercent: 0,
  priceStyleWhileUpdating: '',
  registration: {
    email: '',
    customerID: '',
    salutation: '',
    title: '',
    firstName: '',
    lastName: '',
    company: '',
    addressZip: '',
    addressCity: '',
    addressCountry: 'Deutschland',
    addressStreet: '',
    contactPhone: '',
    mailNotification: false,
    billingFirstName: '',
    billingLastName: '',
    billingCompany: '',
    billingZip: '',
    billingCity: '',
    billingCountry: 'Deutschland',
    billingStreet: '',
    postAccountIsLoading: false,
  },
  surchargeSum: 0,
  processor: '',
}

const setHotelDetail = (state, action) => {
  return {
    ...state,
    isLoading: false,
    hotelDetail: action.res || '',
  }
}

const updateOfferPrice = (state, action) => {
  let selectedRoom = state.hotelDetail?.BookingRooms?.filter(room => room.BookingRoomCategory.shortcode === state.subscriptionRoom) || []
  let selectedOffer = []
  if(selectedRoom.length > 0) {
    selectedOffer = selectedRoom[0].BookingOffers.filter(offer =>
      (offer.BookingSubscriptionCategory.nightsPerMonth === state.subscriptionCategory) &&
      (offer.BookingSubscriptionDuration.months === state.subscriptionDuration)
    )
  }
  let pricePerMonth = 0
  let pricePerNight = 0
  let pricePerMonthWithDiscount = 0
  let pricePerNightWithDiscount = 0
  let discountPerMonth = 0
  let discountPerNight = 0
  let offerID = 'no-offer'
  let BookingOfferDiscount = {}
  let savingsInPercent = 0
  let product = null
  let shareable = false
  if(selectedOffer.length > 0) {
    pricePerMonth = selectedOffer[0].pricePerMonth
    pricePerNight = selectedOffer[0].pricePerNight
    pricePerMonthWithDiscount = selectedOffer[0].pricePerMonthWithDiscount
    pricePerNightWithDiscount = selectedOffer[0].pricePerNightWithDiscount
    discountPerMonth = selectedOffer[0].discountPerMonth
    discountPerNight = selectedOffer[0].discountPerNight
    offerID = selectedOffer[0].offerID
    BookingOfferDiscount = selectedOffer[0].BookingOfferDiscount
    savingsInPercent = selectedOffer[0].savingsInPercent
    product = selectedOffer[0].BookingSubscriptionCategory.shareable ? 'business' : 'single'
    shareable = selectedOffer[0].BookingSubscriptionCategory.shareable
  }
  return {
    ...state,
    subscriptionRoomUuid: selectedRoom[0]?.uuid || '',
    priceStyleWhileUpdating: '',
    pricePerMonth: pricePerMonth,
    pricePerNight: pricePerNight,
    pricePerMonthWithDiscount: pricePerMonthWithDiscount,
    pricePerNightWithDiscount: pricePerNightWithDiscount,
    discountPerMonth: discountPerMonth,
    discountPerNight: discountPerNight,
    offerID: offerID,
    BookingOfferDiscount: BookingOfferDiscount,
    savingsInPercent: savingsInPercent,
    product: product,
    shareable: shareable,
  }
}

const updateOfferPriceAnimation = (state, action) => {
  return {
    ...state,
    priceStyleWhileUpdating: 'bounce-top'
  }
}

const setSubscriptionCategory = (state, action) => {
  return {
    ...state,
    subscriptionCategory: action.res,
  }
}

const setSubscriptionRoom = (state, action) => {
  return {
    ...state,
    subscriptionRoom: action.res || '',
  }
}

const setSubscriptionDuration = (state, action) => {
  return {
    ...state,
    subscriptionDuration: action.res || '',
  }
}

const setSubscriptionStartDate = (state, action) => {
  return {
    ...state,
    subscriptionStartDate: new Date(action.res) || '',
    bookingCalendarFrom: max([new Date(action.res), tomorrow()])
  }
}

const setBookingCalendarFromTo = (state, action) => {
  const startDate = getDateAsUTC(new Date(action.res?.subscriptionData?.startDate))
  const endDate = action.res?.subscriptionData?.endDate
    ? getDateAsUTC(new Date(action.res?.subscriptionData?.endDate))
    : dateXDaysFromDate(tomorrow(), 90)
  return {
    ...state,
    bookingCalendarFrom: max([startDate, tomorrow()]),
    bookingCalendarTo: min([endDate, dateXDaysFromDate(tomorrow(), 90)])
  }
}

const setSelectedNights = (state, action) => {
  return {
    ...state,
    selectedNights: action.res || [],
  }
}

const setPostAccountStart = (state, action) => {
  return {
    ...state,
    registration: {
      ...state.registration,
      postAccountIsLoading: true,
    }
  }
}

const setPostAccountSuccess = (state, action) => {
  return {
    ...state,
    registration: {
      ...state.registration,
      customerID: action.res?.customerID || '',
      email: action.res?.email || '',
      postAccountIsLoading: false,
    }
  }
}

const setPostAccountFail = (state, action) => {
  return {
    ...state,
    registration: {
      postAccountIsLoading: false,
    }
  }
}

const setPutAccountSuccess = (state, action) => {
  return {
    ...state,
    registration: {
      ...state.registration,
      ...action.res.data,
    }
  }
}

const updateCart = (state, action) => {
  return {
    ...state,
    surchargeSum: action.res.surchargeSum || null,
    processor: action.res.processor || '',
  }
}

const updateRegistrationDetailsFromProfile = (state, action) => {
  return {
    ...state,
    registration: {
      ...state.registration,
      firstName: action.res.firstName || '',
      lastName: action.res.lastName || '',
      company: action.res?.BusinessCompany?.name || '',
      addressStreet: action.res.addressStreet || '',
      addressZip: action.res.addressZip || '',
      addressCity: action.res.addressCity || '',
      addressCountry: action.res.addressCountry || 'Deutschland',
      billingFirstName: action.res.billingFirstName || '',
      billingLastName: action.res.billingLastName || '',
      billingCompany: action.res.billingCompany || '',
      billingStreet: action.res.billingStreet || '',
      billingZip: action.res.billingZip || '',
      billingCity: action.res.billingCity || '',
      billingCountry: action.res.billingCountry || 'Deutschland',
      contactPhone: action.res.contactPhone || '',
    }
  }
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_HOTEL_DETAIL_SUCCESS: return setHotelDetail(state, action)
    case actionTypes.UPDATE_OFFER_SELECTION_SUBSCRIPTION_CATEGORY: return setSubscriptionCategory(state, action)
    case actionTypes.UPDATE_OFFER_SELECTION_SUBSCRIPTION_ROOM: return setSubscriptionRoom(state, action)
    case actionTypes.UPDATE_OFFER_SELECTION_SUBSCRIPTION_DURATION: return setSubscriptionDuration(state, action)
    case actionTypes.UPDATE_OFFER_SELECTION_SUBSCRIPTION_START_DATE: return setSubscriptionStartDate(state, action)
    case actionTypes.UPDATE_CUSTOMER_SELECTED_SUBSCRIPTION: return setBookingCalendarFromTo(state, action)
    case actionTypes.UPDATE_OFFER_SELECTION_NIGHTS: return setSelectedNights(state, action)
    case actionTypes.UPDATE_OFFER_PRICE: return updateOfferPrice(state, action)
    case actionTypes.UPDATE_OFFER_PRICE_ANIMATION: return updateOfferPriceAnimation(state, action)
    case actionTypes.POST_ACCOUNT_START: return setPostAccountStart(state, action)
    case actionTypes.POST_ACCOUNT_SUCCESS: return setPostAccountSuccess(state, action)
    case actionTypes.POST_ACCOUNT_FAIL: return setPostAccountFail(state, action)
    case actionTypes.PUT_ACCOUNT_SUCCESS: return setPutAccountSuccess(state, action)
    case actionTypes.PUT_CHECKOUT_UPDATE_CART_SUCCESS: return updateCart(state, action)
    case actionTypes.GET_CUSTOMER_PROFILE_SUCCESS: return updateRegistrationDetailsFromProfile(state, action)
    default: return state
  }
}

export default reducer
