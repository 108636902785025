import * as actionTypes from '../actions/actionTypes';

const initialState = {
  citiesLoading: false,
  hotelsLoading: false,
  hotelDetailsLoading: false,
  cityList: [{
    cityId: 'muenchen',
    cityName: 'München',
    cityCenterLat: 48.1375377882454,
    cityCenterLong: 11.575768313666547,
    zoomLevel: 11,
    hotelRequestModulePosition: null,
  }],
  nightList: [
    {n: 4},
  ],
  nightListCurrentProduct: [],
  hotelList: [],
  sortList: [
    {id: 'mfh', n: 'Empfehlung', s:'', o:''},
    {id: 'p1', n: 'Preis', s:'price', o:'asc'},
    {id: 'p2', n: 'Ersparnis', s:'savings', o:'desc'},
    {id: 'p2', n: 'Aktionsangebote', s:'discount', o:'desc'},
    {id: 's2', n: 'Sterne', s:'ratingStars', o:'desc'},
  ],
  searchCity: {
    cityId: null,
    cityName: null,
    cityCenterLat: null,
    cityCenterLong: null,
    zoomLevel: 11,
    hotelRequestModulePosition: null,
  },
  searchNights: 4,
  searchSort: '',
  searchOrder: '',
  searchProduct: 'single',
  searchFilter: '',
  inputCity: {
    cityId: 'muenchen',
    cityName: 'München',
    cityCenterLat: 48.1375377882454,
    cityCenterLong: 11.575768313666547,
    zoomLevel: 11,
    hotelRequestModulePosition: null,
  },
  inputNights: 4,
  inputSort: {id: 'mfh', n: 'Empfehlung', s:'', o:''},
  filterList: [
    {id:'kitchen_kitchenette', n:'Küche/ Kitchenette'},
    {id:'luggage', n:'Gepäckaufbewahrung'},
    {id:'desk', n:'Schreibtisch'},
    {id:'fitness', n:'Fitness'},
    {id:'pool', n:'Pool'},
    {id:'bar_restaurant', n:'Bar/ Restaurant'},
    {id:'metro', n:'In S-Bahn Nähe'},
    {id:'underground', n:'In U-Bahn Nähe'},
  ],
  inputFilter: {
    cooking: false,
    luggage: false,
    desk: false,
    fitness: false,
    pool: false,
    laundry: false,
    metro: false,
    underground: false,
  }
}

const setCitiesLoading = (state, action) => {
  return {
    ...state,
    citiesLoading: true
  }
}

const setHotelsLoading = (state, action) => {
  return {
    ...state,
    hotelsLoading: true
  }
}

const setHotelDetailsLoading = (state, action) => {
  return {
    ...state,
    hotelDetailsLoading: true
  }
}

const setCityList = (state, action) => {
  return {
    ...state,
    citiesLoading: false,
    cityList: action.res || [],
  }
}

const setNightList = (state, action) => {
  const shareable = state.searchProduct === 'business' ? 1 : 0
  const filteredList = action.res.filter(x => x?.s === shareable)
  return {
    ...state,
    nightList: action.res || [],
    nightListCurrentProduct: filteredList,
  }
}

const setSearchCity = (state, action) => {
  return {
    ...state,
    searchCity: action.res || {},
  }
}

const setSearchNights = (state, action) => {
  return {
    ...state,
    searchNights: action.res,
  }
}

const setSearchSort = (state, action) => {
  return {
    ...state,
    searchSort: state.inputSort.s,
    searchOrder: state.inputSort.o,
  }
}

const setSearchProduct = (state, action) => {
  const shareable = action.res === 'business' ? 1 : 0
  const filteredList = state.nightList.filter(x => x?.s === shareable)
  return {
    ...state,
    searchProduct: action.res,
    nightListCurrentProduct: filteredList,
  }
}

const setSearchFilter = (state, action) => {
  const filteredAttributes = []
  for (const [key, value] of Object.entries(state.inputFilter)) {
    if(value) filteredAttributes.push(key)
  }
  const filterQueryString = filteredAttributes.join('|')
  return {
    ...state,
    searchFilter: filterQueryString,
  }
}

const setInputCity = (state, action) => {
  return {
    ...state,
    inputCity: action.res || {},
  }
}

const setInputNights = (state, action) => {
  return {
    ...state,
    inputNights: action.res,
  }
}

const setInputSort = (state, action) => {
  return {
    ...state,
    inputSort: action.res,
  }
}

const setInputFilter = (state, action) => {
  return {
    ...state,
    inputFilter: {
      ...state.inputFilter,
      ...action.res,
    },
  }
}

const resetInputFilter = (state, action) => {
  return {
    ...state,
    inputFilter: initialState.inputFilter,
  }
}

const setHotelList = (state, action) => {
  return {
    ...state,
    hotelsLoading: false,
    hotelList: action.res || [],
  }
}

const setHotelDetail = (state, action) => {
  return {
    ...state,
    hotelDetailsLoading: false,
    searchCity: action.res.BookingCity,
  }
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_HOTEL_DETAIL_SUCCESS: return setHotelDetail(state, action)
    case actionTypes.GET_CITIES_START: return setCitiesLoading(state, action)
    case actionTypes.GET_CITIES_SUCCESS: return setCityList(state, action)
    case actionTypes.GET_NIGHTS_SUCCESS: return setNightList(state, action)
    case actionTypes.GET_ALL_HOTELS_START: return setHotelsLoading(state, action)
    case actionTypes.GET_ALL_HOTELS_SUCCESS: return setHotelList(state, action)
    case actionTypes.GET_HOTEL_DETAIL_START: return setHotelDetailsLoading(state, action)
    case actionTypes.UPDATE_SEARCH_CITY: return setSearchCity(state, action)
    case actionTypes.UPDATE_SEARCH_NIGHTS: return setSearchNights(state, action)
    case actionTypes.UPDATE_SEARCH_SORT: return setSearchSort(state, action)
    case actionTypes.UPDATE_SEARCH_PRODUCT: return setSearchProduct(state, action)
    case actionTypes.UPDATE_SEARCH_FILTER: return setSearchFilter(state, action)
    case actionTypes.UPDATE_INPUT_CITY: return setInputCity(state, action)
    case actionTypes.UPDATE_INPUT_NIGHTS: return setInputNights(state, action)
    case actionTypes.UPDATE_INPUT_SORT: return setInputSort(state, action)
    case actionTypes.UPDATE_INPUT_FILTER: return setInputFilter(state, action)
    case actionTypes.RESET_INPUT_FILTER: return resetInputFilter(state, action)
    default: return state
  }
}

export default reducer
