import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonBack} from "../components/Buttons";
import {DayPicker} from "react-day-picker";
import deLocale from "date-fns/locale/de";
import {
  addTimezoneOffsetFromDateArray,
  compareDates,
  compareDateToArray,
  dateXDaysFromDate,
  filterDayArrayByRange,
  formatDateDayMonthWithoutYear,
  getDateAsUTC,
  mergeMultipleDateRangesToArray,
  removeTimeFromDate, removeTimezoneOffset,
  yesterday
} from "../functions/dateHelper";
import {add, isAfter} from "date-fns";
import * as actions from "../store/actions";
import DayPickerCustomDay from "../components/DayPickerCustomDay";
import {Link, useParams} from "react-router-dom";
import {calcSelectedNightsCountFromBookingDelta, getSurchargeByRoomAndDay} from "../functions/bookingHelper";
import {DayPickerSummary} from "../components/DayPickerSummary";
import Tippy from "@tippyjs/react";
import CustomerSubscriptionSelection from "../components/CustomerSubscriptionSelection";
import {segmentTypeLabel} from "../functions/enum";
import {DayPickerLegend} from "../components/DayPickerLegend";
import LoadingSpinner from "../assets/svg/LoadingSpinner";
import CustomerCompanyEmployeeSelection from "../components/CustomerCompanyEmployeeSelection";
import Info from "../assets/svg/Info";

const CustomerBooking = () => {
  const dispatch = useDispatch()

  const { subscriptionID } = useParams()

  useEffect(() => {
    dispatch(actions.getCustomerSubscriptions(subscriptionID))
  }, [dispatch, subscriptionID])

  /* date helper */
  const today = new Date().setHours(0,0,0,0)
  const tomorrow = add(today, {days: 1})

  /* redux states */
  const subscription = useSelector(state => state.customer.selectedSubscription)
  const bookingReservation = useSelector(state => state.customer.bookingReservation)
  const profile = useSelector(state => state.customer.profile)
  const accountType = profile.accountType
  const readOnlyMode = (accountType === 'businessUser' && profile.selfService === false)
  const updateReservationsLoading = useSelector(state => state.customer.updateReservationsLoading)
  const companySelectedEmployee = useSelector(state => state.customer.companySelectedEmployee)
  const bookingReservationBySelectedEmployee = useSelector(state => state.customer.bookingReservationBySelectedEmployee)
  const bookingReservationList = subscription?.subscriptionData?.shareable
    ? bookingReservationBySelectedEmployee
    : bookingReservation

  const bookingReservationDayArray = bookingReservationList.map(br => br.arrivalDate)
  const bookingReservationDelta = useSelector(state => state.customer.bookingReservationDelta)
  const bookingReservationDeltaDayArray = bookingReservationDelta.map(br => br.arrivalDate)
  const selectedNightsWithoutOverlap = [
    ...bookingReservationList.filter(x => compareDateToArray(x.arrivalDate, bookingReservationDeltaDayArray) === false),
    ...bookingReservationDelta].sort((a,b)=>a.arrivalDate.getTime()-b.arrivalDate.getTime())
  const selectedNightsDayArrayWithoutOverlap = selectedNightsWithoutOverlap.map(br => br.arrivalDate)

  const hotelDetail = useSelector(state => state.offer.hotelDetail)
  const subscriptionRoomUuid = useSelector(state => state.customer.selectedSubscription?.subscriptionData?.BookingRoom?.uuid || '')

  const bookingReservationDeltaExceeded = useSelector(state => state.customer.bookingReservationDeltaExceeded)

  const specialDateRanges = hotelDetail?.BookingSpecialDates || []
  const specialDateRangesWithSurchargeOrBlackOut = specialDateRanges.filter(date => {
    const surchargeForRoom = date.BookingSpecialDateSurcharges.filter(surcharge => surcharge?.BookingRoom?.uuid === subscriptionRoomUuid)
    return(date.blackOut === true || date.fullyBooked === true || surchargeForRoom.length > 0)
  })
  const specialDatesMerged = mergeMultipleDateRangesToArray(specialDateRangesWithSurchargeOrBlackOut)
  const specialDates = filterDayArrayByRange(specialDatesMerged, tomorrow, dateXDaysFromDate(today, 90))

  const unbookableCycles = subscription?.subscriptionEvaluation?.filter(cycle => cycle?.segment?.bookingAllowed === false) || []
  const blackOutRanges = hotelDetail?.BookingSpecialDates?.filter(x => x.blackOut === true || x.fullyBooked === true) || []
  const blackOutRangesMerged = mergeMultipleDateRangesToArray([...blackOutRanges,...unbookableCycles])
  const blackOutDaysWithoutActiveReservations = blackOutRangesMerged.filter(x => !compareDateToArray(x, selectedNightsDayArrayWithoutOverlap))

  /* local state */
  const [defaultMonth] = useState(today)
  const [month, setMonth] = useState(today)

  /* configs */
  const specialDateStyle = { border: '2px solid #cbcbcb' }
  //const noGuaranteeDays = dateArrayFromDate(6, tomorrow)
  //const noGuaranteeStyle = { textDecorationLine: 'underline', textDecorationStyle: 'wavy', textDecorationColor: '#CCC' }
  const deltaDaysBookStyle = { backgroundColor: '#83c083' }
  const deltaDaysBookDays = bookingReservationDelta.filter(d => d.request === 'book').map(d => d.arrivalDate)
  const deltaDaysBookDaysSurchargeSum = deltaDaysBookDays.reduce((accumulator, day) => {
    const surcharge = getSurchargeByRoomAndDay(subscriptionRoomUuid, day, specialDateRanges)?.surcharge || 0
    return accumulator + Number(surcharge)
  },0)
  const deltaDaysCancelStyle = { backgroundColor: '#C08388' }
  const deltaDaysCancelDays = bookingReservationDelta.filter(d => d.request === 'cancel').map(d => d.arrivalDate)
  const reservationsWithoutConfirmation = bookingReservationList.filter(d => d.status === 'new' && isAfter(removeTimeFromDate(d.arrivalDate), yesterday())).map(d => d.arrivalDate)
  const reservationsWithoutConfirmationStyle = { backgroundColor: 'rgba(37,169,213,0.50)' }
  const reservationsRebookHotel = bookingReservationList.filter(d => d.rebookHotel || d.message).map(d => d.arrivalDate)
  const reservationsRebookHotelStyle = { border: '2px solid #fb923c' }
  const companyInvites = useSelector(state => state.customer.companyInvites)
  const companyInvitesList = companyInvites?.BusinessCompany?.BusinessUserInvitations || []
  const noInvites = subscription?.subscriptionData?.shareable && companyInvitesList.length === 0

  /* functions */
  const unselectDay = (day) => {
    let deltaList = [...bookingReservationDelta]
    const cleanDay = getDateAsUTC(removeTimezoneOffset(day))

    if(compareDateToArray(cleanDay, bookingReservationDeltaDayArray)) {
      if(compareDateToArray(cleanDay, bookingReservationDayArray)) {
        /* day is in delta list (with 'cancel' request) and in booked list -->  remove delta entry */
        deltaList = deltaList.filter(item => compareDates(item.arrivalDate, cleanDay) === false)
      } else {
        /* day is in delta list (with 'book' request) but not in booked list -->  remove delta entry */
        deltaList = deltaList.filter(item =>  compareDates(item.arrivalDate, cleanDay) === false)
      }
    } else {
      /* day is not in delta list, but already booked  --> add new delta entry with action request = 'cancel' */
      deltaList.push({
        arrivalDate:cleanDay,
        request:'cancel',
      })
    }
    dispatch(actions.updateCustomerBookingReservationDelta(deltaList))
  }

  const handleDayClick = (day, { selected }) => {
    if(!readOnlyMode) {
      if (selected) {
        unselectDay(day)
      } else {
        /* add day to selection with action request 'book' */
        const deltaList = [...bookingReservationDelta]
        const cleanDay = removeTimezoneOffset(day)

        deltaList.push({
          arrivalDate:cleanDay,
          request:'book',
        })
        deltaList.sort((a,b)=>a.arrivalDate.getTime()-b.arrivalDate.getTime())
        dispatch(actions.updateCustomerBookingReservationDelta(deltaList))
      }
    }

  }

  const handleSaveSelectedNights = () => {
    const payload = {
      subscriptionID: subscriptionID,
      bookingReservations: bookingReservationDelta,
      companySelectedEmployee: companySelectedEmployee?.invitationID || null,
    }
    dispatch(actions.putCustomerBookingReservation(payload))
  }

  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <div className="flex flex-wrap">
          <div><ButtonBack /></div>
        </div>

        <div className="text-center text-3xl font-light mt-8">Übernachtungen</div>
        <div className="text-center text-md font-light mt-2">Abo-Nächte buchen oder stornieren</div>

        <div className="flex flex-wrap justify-center mt-10">
          <div className="w-full sm:w-80"><CustomerSubscriptionSelection /></div>
          {accountType === 'standalone' && subscription?.subscriptionData?.shareable
            ? <div className="w-full sm:w-80 mt-6 sm:mt-0"><CustomerCompanyEmployeeSelection /></div>
            : ''
          }
        </div>

        { accountType === 'standalone' && noInvites
          ? <div className="flex justify-center">
            <div className="mt-6 p-2 bg-amber-50">Lege in den <Link to={'/dashboard/company/invite/'} className="underline hover:text-secondaryBlue">
            Einstellungen</Link> weitere Personen an, um für diese buchen zu können.</div>
            </div>
          : ''
        }

        { subscription?.subscriptionID
          ? <div className={`flex justify-center mt-8`}>
              <DayPicker
                mode="uncontrolled"
                numberOfMonths={window.innerWidth > 800 ? 2 : 1}
                selected={addTimezoneOffsetFromDateArray(selectedNightsDayArrayWithoutOverlap)}
                onDayClick={handleDayClick}
                defaultMonth={defaultMonth}
                fromDate={new Date(subscription?.subscriptionData?.bookingCalendar?.from)}
                toDate={new Date(subscription?.subscriptionData?.bookingCalendar?.to)}
                disabled={blackOutDaysWithoutActiveReservations}
                locale={deLocale}
                showWeekNumber={true}
                showOutsideDays={false}
                fixedWeeks={false}
                month={month}
                onMonthChange={setMonth}
                modifiers={{
                  special: addTimezoneOffsetFromDateArray(specialDates),
                  reservationsWithoutConfirmation: addTimezoneOffsetFromDateArray(reservationsWithoutConfirmation),
                  deltaDaysBook: addTimezoneOffsetFromDateArray(deltaDaysBookDays),
                  deltaDaysCancel: addTimezoneOffsetFromDateArray(deltaDaysCancelDays),
                  reservationsRebookHotel: addTimezoneOffsetFromDateArray(reservationsRebookHotel),
                }}
                modifiersStyles={{
                  special: specialDateStyle,
                  reservationsWithoutConfirmation: reservationsWithoutConfirmationStyle,
                  deltaDaysBook: deltaDaysBookStyle,
                  deltaDaysCancel: deltaDaysCancelStyle,
                  reservationsRebookHotel: reservationsRebookHotelStyle,
                }}
                components={{ Day: DayPickerCustomDay }}
              />
            </div>
          : <div className="flex justify-center items-center h-48">
              <LoadingSpinner /><br/>
              <div>Kalender lädt</div>
            </div>
        }
        <DayPickerLegend />


        {
          subscription?.subscriptionEvaluation?.filter(x => x.label !== 'historic').map(m => {
            const reservationsCountOverall = calcSelectedNightsCountFromBookingDelta(m.dateFrom, m.dateTo, bookingReservationDelta) + m.reservationsThisCycle
            const selectedNightsDayArray = selectedNightsWithoutOverlap.map(sn => sn.arrivalDate)
            const selectedNightsForThisMonth = selectedNightsDayArray.filter(night => (
              getDateAsUTC(night) >= getDateAsUTC(new Date(m.dateFrom)) && getDateAsUTC(night) <= getDateAsUTC(new Date(m.dateTo)))
            )
            const isPause = m.segment.segmentType !== 'subscription'

            return(
              <div key={m.cycle}>

                <div className="text-center text-base mt-4 mb-6 text-gray-600">
                  <div className={`text-xl font-bold ${isPause ? 'text-secondaryGray' : 'text-secondaryBlue'}`}>
                    {segmentTypeLabel[m.segment.segmentType]} {formatDateDayMonthWithoutYear(m.dateFrom)} - {formatDateDayMonthWithoutYear(m.dateTo)}
                  </div>
                  {m.segment.bookingAllowed
                    ? subscription?.subscriptionData?.shareable || m?.segment?.nightsPerMonth === 0
                      ? subscription?.subscriptionData?.shareable && accountType === 'standalone' //admin only
                        ? <div>
                          <Tippy theme="light" content={
                            <div>
                              <div>Durch {companySelectedEmployee.firstName} genutzte Nächte: <b>{selectedNightsForThisMonth.length}</b></div>
                              <div>Durch andere Personen genutzte Nächte: <b>{reservationsCountOverall - selectedNightsForThisMonth.length}</b></div>
                            </div>}>
                              <div>
                                <span className="font-semibold">{reservationsCountOverall}</span> Nächte gewählt
                                <Info className="inline w-4 h-4 mb-1 ml-1" />
                              </div>
                            </Tippy>
                          </div>
                        : <div>
                            <span className="font-semibold">{selectedNightsForThisMonth.length}</span> {selectedNightsForThisMonth.length === 1 ? 'Nacht':'Nächte'} gewählt
                          </div>
                      : <div className="">Du hast <span className={reservationsCountOverall > m.availableNightsThisCycle ? 'text-red-700' : 'text-green-700'}>
                        {m.carryoverNightsFromPreviousCycle > 0 || m.nightsOffset !== 0 || subscription?.subscriptionData?.shareable
                          ? <Tippy theme="light" content={
                            <div>
                              <div className="">Verfügbare Abo-Nächte aus diesem Monat: <b>{m.segment.nightsPerMonth}</b></div>
                              {m.carryoverNightsFromPreviousCycle
                                ? <div className="">Übertragene Nächte aus dem Vormonat: <b>{m.carryoverNightsFromPreviousCycle}</b></div>
                                : ''}
                              {m.nightsOffset > 0
                                ? <div className="">Gutschrift MyFlexHome Support: <b>{m.nightsOffset}</b></div>
                                : ''}
                              {m.nightsOffset < 0
                                ? <div className="">Abzug MyFlexHome Support: <b>{m.nightsOffset}</b></div>
                                : ''}
                            </div>}>
                            <span><span className="font-semibold">{reservationsCountOverall}</span> von <span
                              className="font-semibold">{m.availableNightsThisCycle}</span> <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" className="inline mb-1">
                              <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"/>
                              </svg></span>
                          </Tippy>
                          : <span><span className="font-semibold">{reservationsCountOverall}</span> von <span
                            className="font-semibold">{m.availableNightsThisCycle}</span></span>
                        }
                        </span> Nächten gewählt.
                        </div>
                    : <div>Keine Buchung möglich</div>
                  }

                </div>

                <DayPickerSummary
                  selectedNights={selectedNightsForThisMonth}
                  specialDates={specialDateRanges}
                  room={subscriptionRoomUuid}
                  deltaDaysBookDays={deltaDaysBookDays}
                  deltaDaysCancelDays={deltaDaysCancelDays}
                  bookingReservation={bookingReservationList}
                  unselectDay={unselectDay}
                  readOnlyMode={readOnlyMode}
                />
              </div>
            )
          })
        }

        {(deltaDaysBookDaysSurchargeSum > 0)
          ? <div className="text-center mx-auto text-gray-900 mt-10">
            Du hast Nächte mit Aufpreis ausgewählt.<br/>
            Mit Klick auf "Buchungen speichern" stimmst<br/>
            du dem Einzug in Höhe von {deltaDaysBookDaysSurchargeSum.toFixed(2).replace('.',',')} € zu.</div>
          : ''
        }

        <div className="text-center mx-auto mt-10">
          {readOnlyMode
            ? /* read only mode for business users without self-service */
              ''
            : bookingReservationDeltaExceeded
              ? /* booking disabled */
                <>
                  <div className="text-sm text-red-700 mb-1">Nächte Limit überschritten. Bitte überprüfe deine Buchungen.</div>
                  <button
                    className="btn bg-gray-300 text-white text-lg rounded p-2 w-56"
                  >
                    Buchungen speichern
                  </button>
                </>
              : updateReservationsLoading
                ? /* loading */
                  <button
                    className="btn bg-gray-400 text-white text-lg rounded w-60 p-2 inline-flex items-center justify-center cursor-not-allowed"
                  >
                    <LoadingSpinner color="white" /> Buchungen speichern
                  </button>
                : /* booking allowed */
                  <button
                    className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded w-60 py-2 inline-flex items-center justify-center"
                    onClick={handleSaveSelectedNights}
                  >
                    Buchungen speichern
                  </button>
          }
        </div>

      </div>

    </div>
  )
}

export default CustomerBooking
